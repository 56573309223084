<template>
  <v-container fluid>
    <div v-if="!idEstudio" class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small @click="getEstudios">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>
      <v-btn class="ma-1" color="primary" small @click="exportToExcel">
        <v-icon left>mdi-microsoft-excel</v-icon>Exportar
      </v-btn>
      <!-- <v-switch
        v-model="mostrarRechazados"
        hide-details
        label="Mostrar rechazados"
        class="mt-0 ml-1 align-self-center"
      /> -->
      <BtnFilter
        v-model="estadosFiltros"
        :items="estudios"
        filter-key="estado_actual"
      />
      <!-- <v-alert v-if="estudiosUrgentes" color="error darken-1" dense class="mb-0" icon="mdi-flag-outline" >Tienes {{ estudiosUrgentes }} urgentes</v-alert> -->
      
      <v-spacer />

      <MensajesSinLeer :key="incrementId" @reload="getEstudios" />
    </div>
    <div v-if="!idEstudio" class="pa-0">
      <v-card-subtitle class="pa-0">
        Los estudios nuevos se envían en
        <router-link
          style="color: var(--v-secondary-base); text-decoration: none"
          to="/borradores"
          >borradores</router-link
        >
      </v-card-subtitle>
    </div>
    <!-- <v-tabs dense v-model="tab" @change="getEstudios">
      <v-tab :value="0" :disabled="loading">Estudios</v-tab>
      <v-tab :value="1" :disabled="loading">Estudios express</v-tab>
    </v-tabs> -->
    <v-card>
      <v-data-table
        :headers="estudiosHeaders"
        :items="estudiosFiltrados"
        :loading="loading"
        :single-select="false"
        item-key="idEstudio"
        checkbox-color="secondary"
        show-select
        v-model="selected"
        :calculate-widths="true"
        class="fixed-checkbox"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [25, 50, -1],
        }"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="estudiosFiltrados"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.estado_actual="{ item }">
          <status-chip v-if="item.estado_actual" :value="item.estado_actual" />
          <v-tooltip bottom v-if="item.fav">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" right small v-on="on" v-bind="attrs"
                >mdi-star</v-icon
              >
            </template>
            En favoritos
          </v-tooltip>
        </template>

        <template v-slot:item.nLlamadas="{ item }">
          <!-- <status-chip :value="item.nLlamadas" /> -->
          <v-chip
            label
            v-if="item.nLlamadas > 0"
            small
            class="v-chip--active"
            outlined
            color="primary"
          >
            <v-icon left>mdi-phone</v-icon>
            {{ item.nLlamadas }}
          </v-chip>
        </template>

        <template v-slot:item.alertas="{ item }">
          <v-menu v-if="item.nAlertas > 0" offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="secondary" overlap dot>
                <v-btn
                  @click.stop="getAlerts(item.idEstudio, 'pendientes')"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-bell-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card>
              <v-list dense>
                <v-list-item
                  :to="`/detalles_estudio?idEstudio=${alert.idEstudio}`"
                  v-for="alert in alertas"
                  :key="alert.idAlerta"
                >
                  <v-list-item-content>
                    {{ alert.idEstudio }} - {{ alert.resumen }}
                  </v-list-item-content>
                  <v-list-item-action-text>{{
                    parseDate(alert.fechaAlerta, false, true, true)
                  }}</v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-menu v-if="item.nAlertasActivas > 0" offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="error darken-2" overlap dot>
                <v-btn
                  @click.stop="getAlerts(item.idEstudio, 'activas')"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-bell-ring</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card>
              <v-list dense min-width="250">
                <v-list-item
                  :to="`/detalles_estudio?idEstudio=${alert.idEstudio}`"
                  v-for="alert in alertas"
                  :key="alert.idAlerta"
                >
                  <v-list-item-content>
                    {{ alert.idEstudio }} - {{ alert.resumen }}
                  </v-list-item-content>
                  <v-list-item-action-text>{{
                    parseDate(alert.fechaAlerta, false, true, true)
                  }}</v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-badge dot overlap v-if="Number(item.nMensajesSinLeer) > 0">
            <v-btn
              :to="`/detalles_estudio?idEstudio=${item.idEstudio}`"
              icon
              small
            >
              <v-icon small>mdi-message-alert-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>

        <template v-slot:item.estado_instalacion="{ item }">
          <status-chip
            v-if="item.estado_instalacion"
            :value="item.estado_instalacion"
          />
        </template>
        <template v-slot:item.estado_cargador="{ item }">
          <status-chip
            v-if="item.estado_cargador"
            :value="item.estado_cargador"
          />
        </template>

        <template v-slot:item.nombreInstaladora="{ item }">
          <v-chip outlined label v-if="item.nombreInstaladora">
            <v-icon small left>mdi-wrench</v-icon>
            {{ item.nombreInstaladora }}
          </v-chip>
        </template>

        <template v-slot:item.nombreTipo="{ item }">
          <v-chip label outlined :color="item.colorTipo">
            <v-icon left>{{ item.iconTipo }}</v-icon>
            {{ item.nombreTipo }}
          </v-chip>
        </template>

        <template v-slot:item.cups="{ item }">
          <span v-if="item.cups">{{ item.cups }}</span>
          <CountryFlag
            v-if="item.cups"
            :country="item.cupsCodigoISO"
            size="small"
            class="ml-0"
          />
        </template>

        <template v-slot:item.enviadoEn="{ item }">
          <span v-text="parseDate(item.enviadoEn, false, true, true)" />
        </template>

        <template v-slot:item.ultimoContacto="{ item }">
          <v-chip
            outlined
            v-text="
              parseDate(item.ultimoContacto, false, true, true) || 'Nunca'
            "
          />
        </template>

        <template v-slot:item.interes="{ item }">
          <v-chip
            pill
            class="v-chip--active"
            label
            outlined
            :color="item.colorInteres"
          >
            <v-icon small left>{{ item.iconInteres }}</v-icon>
            <span class="font-weight-bold text-caption">{{
              item.interes
            }}</span>
          </v-chip>
        </template>

        <template v-slot:item.nombreTarifa="{ item }">
          <v-chip small v-text="item.nombreTarifa" label />
        </template>

        <template v-slot:item.nombreEmpresa="{ item }">
          <empresa-chip small :value="item.nombreEmpresa" label />
        </template>

        <template v-slot:item.formato="{ item }">
          <v-chip label outlined small color="info">
            <v-icon small left>mdi-file-document-outline</v-icon>
            {{ item.formato }}
          </v-chip>
        </template>

        <template v-slot:item.potenciaInstalacion="{ item }">
          <v-chip label outlined v-if="item.potenciaInstalacion != null">
            <v-icon left>mdi-solar-panel</v-icon>
            {{ item.potenciaInstalacion }} kWp
          </v-chip>
        </template>

        <template v-slot:item.precioVenta="{ item }">
            {{ item.precioVenta ? money.format( item.precioVenta ) : null }}
        </template>

        <template v-slot:item.idEstudio="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="secondary"
                :to="`/detalles_estudio?idEstudio=${item.idEstudio}`"
                text
                >
                <v-icon v-if="item.isAutoestudio" left>mdi-lightning-bolt</v-icon>
                {{ item.idEstudio }}</v-btn
              >
            </template>
            Detalles estudio {{ item.isAutoestudio ? '(Express)' : '' }}
          </v-tooltip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                color="secondary"
                target="_blank"
                :href="`https://www.google.com/maps/search/?api=1&query=${item.latitud}%2C${item.longitud}`"
              >
                <v-icon>mdi-google-maps</v-icon>
              </v-btn>
            </template>
            Abrir en Google Maps
          </v-tooltip>

          <v-btn
            color="secondary"
            @click.stop="detailsId = item.idEstudio"
            outlined
            class="rounded-pill"
            small
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="secondary"
            @click.stop="toggleFav(item)"
            class="rounded-pill"
            icon
            small
          >
            <v-icon v-if="!item.fav">mdi-star-outline</v-icon>
            <v-icon v-if="item.fav">mdi-star</v-icon>
          </v-btn>

          <v-tooltip bottom v-if="item.urgente">
            <template v-slot:activator="{ attrs, on }">
              <v-icon v-bind="attrs" v-on="on" color="error darken-1"
                >mdi-flag-outline</v-icon
              >
            </template>
            Urgente
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!-- <v-dialog persistent max-width="600px" width="100%" v-model="cambiarEstado">
      <cambiar-estado
        :key="cambiarEstado"
        @reload="getEstudios"
        :estudios="selected.map((c) => c.idEstudio)"
        @close="cambiarEstado = false"
      />
    </v-dialog> -->

    <v-dialog
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar>
        <v-toolbar-title>Estudio {{ detailsId }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="detailsId = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <DetallesEstudio
          :key="detailsId"
          :_idEstudio="detailsId"
          @reload="getEstudios"
          @close="cambiarEstado = false"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  perColumnFilter,
  parseDate,
  downloadFile,
  jsonToCsv,
} from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    EmpresaChip: () => import("@/components/EmpresaChip.vue"),
    BtnFilter: () => import("@/components/BtnFilter.vue"),
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    MensajesSinLeer: () => import("@/components/MensajesSinLeer.vue"),
    EnviarEstudio: () => import("./EnviarEstudio.vue"),
    DetallesEstudio: () => import("./DetallesEstudio.vue"),
    CountryFlag: () => import("vue-country-flag"),
  },
  props: {
    idEstudio: null,
    eliminados: Boolean,
  },
  mounted() {
    this.getEstudios();
  },
  computed: {
    estudiosFiltrados() {
      return this.estudios.filter((imp) => {
        for (let [key, value] of Object.entries(this.estadosFiltros))
          if (imp.estado_actual == key) return value;
        return true;
      });
    },
    estudiosUrgentes() {
      return this.estudios.filter(est => est.urgente).length
    }
  },
  methods: {
    async getEstudios() {
      this.loading = true;
      const { data: estudios } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/estudios`,
        params: {
          idUsuario: this.$store.getters.getTokenPayload.idUsuario,
          idEstudio: this.idEstudio,
          eliminados: this.eliminados,
          borradores: false,
          autoestudios: this.tab,
        },
      });
      // this.estudios = estudios.map( e => ({ ...e, instaladoras : e.instaladoras ? e.instaladoras.split(',') : [], datos: JSON.parse(e.datos) }) )
      this.estudios = estudios;
      this.incrementId++;
      this.loading = false;
    },
    async toggleFav(item) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/favoritos`,
        data: {
          idEstudio: item.idEstudio,
        },
      });
      // this.getEstudios();
      item.fav = !item.fav;
      this.$root.$emit(
        "snack",
        `Estudio ${idEstudio} ${
          item.fav ? "eliminado de" : "añadido a"
        } favoritos`
      );
      this.$root.$emit("getFav");
    },
    exportToExcel(items) {
      const getDatatype = (key) =>
        (this.estudiosHeaders.find((h) => h.value == key) || {}).dataType;

      if (!Array.isArray(items))
        items = this.estudiosFiltrados.filter((con) =>
          Object.entries(con).every(([key, value]) =>
            perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
          )
        );

      const headers = [
        { k: "idEstudio" },
        { k: "enviadoEn" },
        { k: "ultimoCambio" },
        { k: "nombreProyecto", alias: "Nombre" },
        { k: "descripcion", alias: "Descripcion" },
        { k: "cups" },
        { k: "nombreTarifa", alias: "Tarifa" },
        { k: "comercial", alias: "Comercial" },
        { k: "subComercial", alias: "Sub Comercial" },
        { k: "latitud", dataType: 'number' },
        { k: "longitud", dataType: 'number' },
        { k: "inclinacion", dataType: 'number' },
        { k: "azimut", dataType: 'number' },
        { k: "interes", alias: "Interés del cliente" },
        { k: "nombreTipo", alias: "Tipo del estudio" },
        { k: "estado_actual", alias: "Estado actual" },
        { k: "estado_actual_int", alias: "Estado actual interno" },
        { k: "nombreInstaladora", alias: "Instaladora" },

        { k: "potenciaInstalacion", alias: "Potencia instalación", dataType: 'number' },
        { k: "precioVenta", alias: "Precio Venta", dataType: 'number' },
      ];

      const csvHeaders = headers.map(({ k, alias, dataType }) => ({
        key: k,
        alias,
        dataType: dataType || getDatatype(k),
      }));

      jsonToCsv(
        items,
        csvHeaders,
        "estudios_renovables_" + parseDate(Date.now(), false, false)
      );
    },
    parseDate,
  },
  data() {
    return {
      tab: 0,
      inlineFilters: {},
      cambiarEstado: false,
      incrementId: 0,
      detailsId: null,
      alertas: [],
      estudiosHeaders: [
        { text: "Estado", value: "estado_actual", dataType: "select" },
        // { text: "Alertas", value: "alertas", filterable: false, sortable: false, },
        // { text: "Llamadas", value: "nLlamadas", },
        {
          text: "Estado instalación",
          value: "estado_instalacion",
          dataType: "select",
        },
        {
          text: "",
          value: "alertas",
          sortable: false,
          filterable: false,
          align: "center",
        },
        {
          text: "Instaladora",
          value: "nombreInstaladora",
          dataType: "select",
          acceso: "EDITAR_ESTUDIOS",
        },
        { text: "Nº Estudio", value: "idEstudio", align: "end" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
          align: "center",
        },
        { text: "Nombre estudio", value: "nombreProyecto" },
        { text: "CUPS", value: "cups" },
        // { text: "Instaladoras", value: "instaladoras", sortable: false, filterable: false, dataType: "select" },
        { text: "Tipo estudio", value: "nombreTipo", dataType: "select" },
        {
          text: "Comercial",
          value: "comercial",
          cellClass: "pa-2 text-no-wrap font-weight-bold",
          sub:
            this.$store.getters.getTokenPayload.esCanal ||
            this.$root.acceso("VER_TODOS_ESTUDIOS"),
        },
        {
          text: "SubComercial",
          value: "subComercial",
          sub:
            this.$store.getters.getTokenPayload.esCanal ||
            this.$root.acceso("VER_TODOS_ESTUDIOS"),
        },
        {
          text: "Interés",
          value: "interes",
          acceso: "VER_TODOS_ESTUDIOS",
          dataType: "select",
        },
        { text: "Estado cargador", value: "estado_cargador", dataType: "select", },
        {
          text: "Portal",
          value: "nombreEmpresa",
          acceso: "VER_TODOS_ESTUDIOS",
          dataType: "select",
        },
        { text: "Formato", value: "formato", acceso: "VER_TODOS_ESTUDIOS" },
        { text: "Fecha Envío", value: "enviadoEn", dataType: "date" },
        // { text: "Último contacto", value: "ultimoContacto", dataType: "date" },
        { text: "Tarifa", value: "nombreTarifa", dataType: "select" },
        { text: "Potencia instalación", value: "potenciaInstalacion" },
        { text: "Precio Venta", value: "precioVenta" },
        // { text: "Precio Total", value: "precioTotal" },
        // { text: "Latitud", value: "lat" },
        // { text: "Longitud", value: "lon" },
        // { text: "Inclinación", value: "inclinacion" },
        // { text: "Azimut", value: "azimut" },
      ]
        .filter((h) => {
          return (
            (h.sub == null || h.sub) &&
            (h.acceso == null || this.$root.acceso(h.acceso))
          );
        })
        .map((header) => {
          return {
            class: "text-no-wrap sticky-header",
            cellClass: "pa-2 text-no-wrap",
            filter: (value) =>
              perColumnFilter(
                value,
                this.inlineFilters[header.value],
                header.dataType
              ),
            dataType: "text",
            ...header,
          };
        }),
      estudios: [],
      selected: [],
      loading: false,

      estadosFiltros: {
        Rechazado: false,
        "No Apto": false,
        "Finalizado por otro": false,
      },

      money: Intl.NumberFormat('de-DE', { currency: 'EUR', style: 'currency' })

    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.detailsId != null && to.name != "EnviarEstudio") {
      this.detailsId = null;
      next(false);
      return;
    }
    next();
  },
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
  background: #f1f1f1 !important;
}
</style>
